import React, { useState, useRef, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image1 from '../images/CODICE logo.webp';
import Image2 from '../images/evonsys.webp';
import Image3 from '../images/creativesoftware.webp';
import Image4 from '../images/ideaMart.webp';
import Image5 from '../images/pagero.webp';
import Image6 from '../images/derana.webp';
import Image7 from '../images/mannar.webp';
import Image8 from '../images/gasma.webp';
import Image9 from '../images/hacksl.webp';
import Image10 from '../images/hackthonslk.webp';
import Image11 from '../images/gdg.webp';
import Image12 from '../images/loonslab.webp';
import Image13 from '../images/maw.webp';
import Image14 from '../images/nadi.webp';
import Image15 from '../images/ct.webp';
import Image16 from '../images/pulse.webp';
import Image17 from '../images/virtusa.webp';
import ClickImage from '../images/click.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles1.css'; // Assume this contains our custom styles
import './parteners.css';

function DarkVariantExample() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 600 && window.innerWidth <= 1100);
  const sliderRef = useRef(null);
  const autoplayTimeoutRef = useRef(null);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 600);
    setIsTablet(window.innerWidth > 600 && window.innerWidth <= 1100);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleManualNavigation = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
      clearTimeout(autoplayTimeoutRef.current);
      autoplayTimeoutRef.current = setTimeout(() => {
        sliderRef.current.slickPlay();
      }, 0); // Resume autoplay after a delay
    }
  };

  // Slider settings
  const settings = {
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="team-cards-section" style={{ padding: '20px', color: '#fff' }}>
      <Container>
        <Slider ref={sliderRef} {...settings}>
          {/* First Card */}
          <div className="slide">
  <Card className="partner-card" style={{ marginBottom: '20px' }}>
    <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
      <img
        src={Image1}
        alt="Title Partner"
        className="partner-image"
        style={{ width: '100%', height: 'auto', marginTop: '10px' }}
      />
    </div>
    <Card.Body>
      <Card.Title>
        <center className="partner-name" style={{ marginTop: '-45px' }}>
          TITLE PARTNER
        </center>
      </Card.Title>
      <Card.Text>
        <center>
          <a href="https://codicetech.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
      </Card.Text>
    </Card.Body>
  </Card>
</div>

{/* Fourth Card */}
<div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image2} alt="Gold Partner" className="partner-image" style={{ width: '65%', height: 'auto',marginTop:"-10px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-75px"}}>GOLD PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://www.evonsys.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>



          {/* Second Card */}
          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image5} alt="Bronze Partner" className="partner-image" style={{ width: '60%', height: 'auto',marginTop:"33px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-3px"}}>BRONZE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://www.pagero.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          {/* Third Card */}
          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image3} alt="Bronze Partner" className="partner-image" style={{ width: '80%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-10px"}}>BRONZE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://www.creativesoftware.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          

          {/* Fifth Card */}
          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image6} alt="Eco-System Partner" className="partner-image" style={{ width: '30%', height: 'auto',marginTop:"10px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-33px",fontSize:"0.9rem"}}>ELECTRONIC MEDIA PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://derana.lk/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image7} alt="Eco-System Partner" className="partner-image" style={{ width: '40%', height: 'auto',marginTop:"5px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-20px"}}>FOOD PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://www.facebook.com/people/Mann%C4%81r/100077231245261/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image8} alt="Eco-System Partner" className="partner-image" style={{ width: '50%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-9px",fontSize:"1.1rem"}}>DIGITAL MEDIA PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="http://gasma.lk/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image16} alt="Eco-System Partner" className="partner-image" style={{ width: '50%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-9px",fontSize:"1.1rem"}}>DIGITAL MEDIA PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://pulse.lk/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image14} alt="Eco-System Partner" className="partner-image" style={{ width: '50%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-9px",fontSize:"1.1rem"}}>DIGITAL MEDIA PARTNER</center>
                  
                </Card.Title>
                <Card.Text>
                <center>
          <a href="http://gasma.lk/nadi/?tag=tamil" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image13} alt="Eco-System Partner" className="partner-image" style={{ width: '45%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-8px"}}>PRINT MEDIA PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://mawbima.lk/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image15} alt="Eco-System Partner" className="partner-image" style={{ width: '45%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-20px"}}>PRINT MEDIA PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://ceylontoday.lk/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image9} alt="Eco-System Partner" className="partner-image" style={{ width: '55%', height: 'auto',marginTop:"-5px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-18px"}}>KNOWLEDGE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://lk.linkedin.com/company/hacksl" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image10} alt="Eco-System Partner" className="partner-image" style={{ width: '80%', height: 'auto',marginTop:"55px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"5px"}}>KNOWLEDGE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://www.linkedin.com/showcase/hackathonsdotlk/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image17} alt="Eco-System Partner" className="partner-image" style={{ width: '50%', height: 'auto',marginTop:"45px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-15px"}}>KNOWLEDGE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://www.virtusa.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image11} alt="Eco-System Partner" className="partner-image" style={{ width: '50%', height: 'auto' }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-15px"}}>KNOWLEDGE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://developers.google.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="slide">
            <Card className="partner-card" style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }} className="partner-image-wrapper">
                <img src={Image12} alt="Eco-System Partner" className="partner-image" style={{ width: '50%', height: 'auto',marginTop:"-10px" }} />
              </div>
              <Card.Body>
                <Card.Title>
                  <center className="partner-name" style={{marginTop:"-33px"}}>KNOWLEDGE PARTNER</center>
                </Card.Title>
                <Card.Text>
                <center>
          <a href="https://loonslab.com/" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }}>
            <img src={ClickImage} alt="Click to visit" style={{ width: '20px', marginRight: '5px' }} />
            <span>  Visit Site</span>
          </a>
        </center>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

         

          
        </Slider>
      </Container>
    </div>
  );
}

export default DarkVariantExample;
